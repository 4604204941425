// import React, { useState } from 'react';
// import './ChatBot.scss'; // Importing the CSS file
// import ChatBuddy from "../../assets/images/chatbuddy.png";

// const Chatbot = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [messages, setMessages] = useState([
//     { sender: 'bot', text: 'Hi , Iam Your Travel Buddy. How can I assist you today?' },
//   ]);
//   const [userInput, setUserInput] = useState('');

//   const predefinedAnswers = {
//     'What is your name?': 'I am your friendly chatbot! from Outc',
//     'How can I book a ticket?': 'Click on the "Book Now" button in the checkout u can compare the prices.',
//     'What services do you offer?': 'We offer flight, hotel, and bus bookings.',
//   };

//   const handleUserMessage = () => {
//     if (userInput.trim()) {
//       setMessages([...messages, { sender: 'user', text: userInput }]);

//       const botResponse =
//         predefinedAnswers[userInput] || "I'm sorry, I don't have an answer for that.";
//       setMessages((prev) => [...prev, { sender: 'bot', text: botResponse }]);

//       setUserInput('');
//     }
//   };

//   return (
//     <>
//       {/* Chatbot Icon */}
//       <div className="chatbot-icon" onClick={() => setIsOpen(!isOpen)}>
//         <img
//           src={ChatBuddy} // Replace with your icon
//           alt="Chatbot Icon"
//         />
//       </div>

//       {/* Chatbot Window */}
//       {isOpen && (
//         <div className="chatbot-window">
//           <div className="chatbot-header">
//             <span>Travel Buddy</span>
//             <button onClick={() => setIsOpen(false)}>X</button>
//           </div>
//           <div className="chatbot-messages">
//             {messages.map((msg, index) => (
//               <div
//                 key={index}
//                 className={`chatbot-message ${msg.sender === 'user' ? 'user-message' : 'bot-message'
//                   }`}
//               >
//                 {msg.text}
//               </div>
//             ))}
//           </div>
//           <div className="chatbot-input">
//             <input
//               type="text"
//               value={userInput}
//               onChange={(e) => setUserInput(e.target.value)}
//               placeholder="Type your question..."
//               onKeyDown={(e) => e.key === 'Enter' && handleUserMessage()}
//             />
//             <button onClick={handleUserMessage}>Send</button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default Chatbot;


import React, { useState } from 'react';
import './ChatBot.scss';
//import ChatBuddy from "../../assets/images/chatbuddy.png";
const predefinedQuestions = [
  'What is your name?',
  'How can I book a ticket?',
  'What services do you offer?',
  'What are your contact details?',
  'Can I cancel my booking?',
  'Do you offer travel insurance?',
];
const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showQuestions, setShowQuestions] = useState(true);
  const [messages, setMessages] = useState([
    { sender: 'bot', text: 'Hi , Iam Your Travel Buddy.    \n How can I assist you today?' },
  ]);
  const [userInput, setUserInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const predefinedQA = {
    'What is your name?': 'I am your friendly chatbot!',
    'How can I book': 'Click on the "Book Now" button on the Checkout.',
    'What services do you offer?': 'We offer flight, hotel, and bus bookings.',
    'How should i Contact?': 'You can reach us at contact@outc.com.',
    'How can I check my booking status?': 'Go to the "My Bookings" section and enter your booking ID.',
    'Can I cancel my booking?': 'Yes, you can cancel your booking from the "My Bookings" section. Cancellation charges may apply.',
    'What is the refund policy?': 'Refunds are processed within 7-10 business days. Cancellation charges may be deducted based on the policy.',
    'Do you offer discounts on bookings?': 'Yes, we regularly provide discounts. Check the "Offers" section for ongoing deals.',
    'Can I reschedule my flight or bus ticket?': 'Rescheduling is available for certain bookings. Please contact our support team for assistance.',
    'How can I change my hotel reservation?': 'You can modify your reservation from the "My Bookings" section or by contacting customer support.',
    'Do you provide travel insurance?': 'Yes, you can add travel insurance while booking your ticket.',
    'What payment methods do you accept?': 'We accept credit/debit cards, UPI, net banking, and digital wallets.',
    'Are there any hidden charges?': 'No, all charges are transparent and displayed before payment.',
    'Can I book tickets for someone else?': 'Yes, you can book tickets for others by providing their details during the booking process.',
    'How do I contact customer support?': 'You can email us at support@outc.com or use the "Contact Us" form on our website.',
    'Do you offer group discounts?': 'Yes, we offer discounts for group bookings. Contact us for details.',
    'Can I get an invoice for my booking?': 'Yes, an invoice is emailed to you once your booking is confirmed.',
    'What documents do I need for flight check-in?': 'You will need a valid ID proof and your e-ticket for check-in.',
    'Can I pre-select seats for my flight or bus?': 'Yes, seat selection is available during the booking process for flights and buses.',
    'How do I know if my bus is delayed?': 'You will receive notifications via email or SMS if there are any delays.',
    'What is the check-in time for hotels?': 'Hotel check-in times vary, but generally, it is after 12:00 PM. Check your booking details for exact timings.',
    'Do you offer 24/7 customer support?': 'Yes, our customer support team is available 24/7 to assist you.',
    'How do I report an issue with my booking?': 'You can report an issue via the "Help Center" or by contacting customer support directly.',
    'Can I add special requests to my hotel booking?': 'Yes, you can add special requests, like early check-in or airport pickup, during the booking process.',
  };


  const predefinedQuestions = Object.keys(predefinedQA);

  // Handle user message submission
  const handleUserMessage = () => {
    if (userInput.trim()) {
      setMessages([...messages, { sender: 'user', text: userInput }]);

      const botResponse =
        predefinedQA[userInput] || "I'm sorry, I don't have an answer for that.";
      setMessages((prev) => [...prev, { sender: 'bot', text: botResponse }]);

      setUserInput('');
      setShowQuestions(false);
      setSuggestions([]); // Clear suggestions
    }
  };

  // Handle input change and update suggestions
  const handleInputChange = (value) => {
    setUserInput(value);

    if (value.trim()) {
      const filteredSuggestions = predefinedQuestions.filter((question) =>
        question.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  // Handle suggestion click
  const handleSuggestionClick = (suggestion) => {
    setUserInput(suggestion);
    setSuggestions([]);
  };

  // Handle predefined question button click
  const handleQuestionClick = (question) => {
    setMessages([...messages, { sender: 'user', text: question }]);
    setShowQuestions(false);
    setMessages((prev) => [
      ...prev,
      { sender: 'bot', text: predefinedQA[question] },
    ]);
  };

  return (
    <>
      {/* Chatbot Icon */}
      <div className="chatbot-icon" onClick={() => setIsOpen(!isOpen)}>
        <img
          src={"https://res.cloudinary.com/dz0wvd3lm/image/upload/v1737467879/chatbuddy_bjy5fy.png"} // Replace with your icon
          alt="Chatbot Icon"
        />
      </div>

      {/* Chatbot Window */}
      {isOpen && (
        <div className="chatbot-window">
          <div className="chatbot-header">
            <span>Travel Buddy!</span>
            <button onClick={() => setIsOpen(false)}>X</button>
          </div>
          <div className="chatbot-messages">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`chatbot-message ${msg.sender === 'user' ? 'user-message' : 'bot-message'
                  }`}
              >
                {msg.text}
              </div>
            ))}
          </div>
          {/* Predefined Questions */}
          {showQuestions && (
            <div className="chatbot-questions">
              {predefinedQuestions.slice(0, 4).map((question, index) => (
                <button
                  key={index}
                  onClick={() => handleQuestionClick(question)}
                  className="question-button"
                >
                  {question}
                </button>
              ))}
            </div>
          )}
          <div className="chatbot-input">
            <input
              type="text"
              value={userInput}
              onChange={(e) => handleInputChange(e.target.value)}
              placeholder="Type your question..."
              onKeyDown={(e) => e.key === 'Enter' && handleUserMessage()}
            />
            <button onClick={handleUserMessage}>Send</button>
          </div>
          {/* Autosuggestions */}
          {suggestions.length > 0 && (
            <ul className="chatbot-suggestions">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
          {/* Predefined Questions */}
          {/* <div className="chatbot-questions">
            {predefinedQuestions.map((question, index) => (
              <button
                key={index}
                onClick={() => handleQuestionClick(question)}
                className="question-button"
              >
                {question}
              </button>
            ))}
          </div> */}
        </div>
      )}
    </>
  );
};

export default Chatbot;
